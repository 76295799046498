import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import Switch from '@material-ui/core/Switch';
import EquipmentsCamerasAndLenses from './EquipmentsCamerasAndLenses';
import Equipments3D from './Equipments3D';
import EquipmentsDrones from './EquipmentsDrones';
import EquipmentsCategory from './EquipmentsCategory'
import SmsDialog from './SmsDialog'
import Autocomplete from 'react-google-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import '../../App.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class NewsPage extends React.Component {

  state = {
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    companyName: '',
    companyNumber: '',
    portfolio: '',
    companyType: "0",
    salutation: '',
    comment: '',
    ip: '',
    eqType: 0,
    isGroup: true,
    acceptTerms: false,
    promoCode: '',
    cameras: [],
    drones: [],
    lenses: [],
    trippleD: [],
    error_firstName: '',
    error_lastName: '',
    error_address: '',
    error_group: '',
    error_companyName: '',
    error_companyNumber: '',
    error_companyType: '',
    error_comment: '',
    error_acceptTerms: '',
    error_phone: '',
    phone: '',
    notInList: false,
    smsDialog: false,
    countryCode: '972',
    inputCode: '',
    errorCode: false,
    code: ''
  };

  componentDidMount() {
    this.getUserIP()
    // this.props.startLoading()
  }

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = " 9725"
    var substring2 = " 97205"
    if (phone.includes(substring1) || phone.includes(substring2)) {
      // console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        // console.log(phone);
        phone = phone.replace(" 9725", "05");
        phone = phone.replace("-", "");
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(" 97205", "05");
        phone = phone.replace("-", "");
        // console.log(phone);
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace("-", "");
      if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3")) {
        if (phone.length == 10 && (/^\d+$/.test(phone))) {
          this.setState({phone: phone})
          return true
        }
      } else {
        return false;
      }
    }
  }

  handleChangeSwitch = name => event => {
    this.setState({[name]: event.target.checked });
  };

  getUserIP = () => {
    axios.get('https://httpbin.org/ip',{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      // console.log("IP");
      var ip = response.data.origin.split(',')[0]
      // console.log(ip);
      this.setState({ip: ip})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  handleChangeText = name => event => {
    // console.log("@@");
    // console.log( event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleChangeLocation = (value) => {
    // console.log(value.description);
    this.setState({ "address": value.description });
  };

  handleChangeSalutation = name => event => {
    this.setState({ [name]: event.target.value });
  };

  registerFinished = (accountId, contactId) => {
    // window.location.reload();
    this.props.main.setState({completed: true})
    // localStorage.setItem("wesnapp-registered", this.props.main.state.phone);
    // //console.log(accountId);
    // //console.log(contactId);
    // localStorage.setItem('wesnapp-accountId', accountId);
    // localStorage.setItem('wesnapp-contactId', contactId);
    // localStorage.setItem("wesnapp-mobile", this.props.main.state.phone);
    // var token = uuid.v1()
    // this.saveUUID(token, accountId)
  }

  registerFailed = (data) => {
    this.props.handleOpenAlert("Oops, something went wrong!")
  }

  saveUUID = (token, accountId) => {
    var data = {"id": accountId}
    axios.post('https://wesnapp.co.il/api/login/save-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      const cookies = new Cookies();
      cookies.set('wesnapp-token', response.data.token, { path: '/' });
      window.location.reload();
    })
    .catch( (error) => {
      window.location.reload();
      //console.log(error);
    });
  }

  checkEquipment = () => {
    if (this.state.cameras.length == 0 && this.state.lenses.length == 0) {
      if (this.state.comment == "") {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  checkGroup = () => {
    if (this.state.isGroup) {
      if (this.state.companyName == "" || this.state.companyNumber == "" || this.state.companyType == "0") {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  registerClicked = () => {
    // this.props.startLoading()
    this.setState({error_phone: "", error_firstName: "",error_lastName: "",error_email: "",error_address: "",error_group: "", error_acceptTerms: "", error_comment: ""})
    if (this.state.firstName == "") {
      this.setState({error_firstName: "First name empty"})
    }
    if (this.state.lastName == "") {
      this.setState({error_lastName: "Last name empty"})
    }
    if (this.state.email == "") {
      this.setState({error_email: "Email empty"})
    } else {
      if (!this.validateEmail(this.state.email)) {
        this.setState({error_email: "Email is not valid"})
      }
    }
    if (!this.validateMobile(this.state.phone)) {
      this.setState({error_phone: "Error phone number"})
    }
    if (this.state.address == "") {
      this.setState({error_address: "Address empty"})
    }
    if (this.checkGroup()) {
      this.setState({error_group: "Please fill all the details about your company"})
    }
    if (this.checkEquipment()) {
      this.setState({error_comment: "Please choose a least 1 camera / 1 lens or describe your equipment bellow"})
    }
    if (this.state.acceptTerms == false) {
      this.setState({error_acceptTerms: "Accept the Terms & Conditions"})
    }
    if (this.state.firstName == "" || this.state.lastName == "" || this.state.email == "" || this.state.address == "") {
      // this.props.stopLoading()
      // this.props.handleOpenAlert('Please fill all the fields.')
    } else if (!this.validateEmail(this.state.email)) {
      // this.props.stopLoading()
      // this.props.handleOpenAlert('Please fill all the fields.')
    } else if (this.checkEquipment()) {
      // this.props.stopLoading()
      // this.props.handleOpenAlert('Please choose a least 1 camera / 1 lens or describe your equipment bellow.')
    } else if (this.checkGroup()) {
      // this.props.stopLoading()
        // this.props.handleOpenAlert('Please fill all the details about your company.')
    } else if (this.state.acceptTerms == false) {
      // this.props.stopLoading()
      // this.props.handleOpenAlert('You need to accept the Terms & Conditions')
    } else if (!this.validateMobile(this.state.phone)) {
      // this.props.stopLoading()
      // this.props.handleOpenAlert('You need to accept the Terms & Conditions')
    }  else {
      this.checkAddress(this.state.address)
    }
  }

  checkAddress = (location) => {
      //console.log(location);
      var data = {"location": location}
      axios.post('https://wesnapp.co.il/api/location/detect',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          console.log(response.data);
          if (response.data != 'error') {
            console.log(response.data);
            if (response.data.full_data.types[0] == 'street_address') {
              // if (response.data.full_data.formatted_address.includes('Israel')) {
                var address = response.data.full_address.split(", ")
                var street = address[0]
                var city = address[1]
                var country = address[2]
                // console.log(city);
                // console.log(country);
                // console.log(street);
                this.setState({city: city, country: country, street: street})
                this.continueToVerification()
                // this.registerPhotographer(country, city, street)

              }
              // else {
              //   this.props.stopLoading()
              //   this.setState({error_address: "Make sure the address you type located in Israel."})
              // }
             else if (response.data.full_data.types[0] == 'route') {

                // if (response.data.full_data.formatted_address.includes('Israel')) {
                  var address = response.data.full_address.split(", ")
                  var street = ""
                  var street = address[0]
                  var city = address[1]
                  var country = address[2]
                  // console.log(city);
                  // console.log(country);
                  // console.log(street);
                  this.setState({city: city, country: country, street: street})
                  this.continueToVerification()

                // } else {
                //   this.props.stopLoading()
                //   console.log(response.data);
                //   this.setState({error_address: "Make sure the address you type located in Israel."})
                // }
              }
              else if (response.data.full_data.types[0] == 'locality') {
                // if (response.data.full_data.formatted_address.includes('Israel')) {
                  var address = response.data.full_address.split(", ")
                  var street = address[0]
                  var city = address[0]
                  var country = address[1]
                  // console.log(city);
                  // console.log(country);
                  // console.log(street);
                  this.setState({city: city, country: country, street: street})
                  this.continueToVerification()

                 } else {
                  console.log(response.data);
                  this.props.stopLoading()
                  this.setState({error_address: "Make sure the address you type located in Israel."})
                }
              } else {
               console.log(response.data);
               this.props.stopLoading()
               this.setState({error_address: "Make sure the address you type located in Israel."})
             }

                // } else {
                //   this.props.stopLoading()
                //   console.log(response.data);
                //   this.setState({error_address: "Make sure the address you type located in Israel."})
                // }
              // } else {
              //   console.log(response.data);
              //   this.props.stopLoading()
              //   this.setState({error_address: "Make sure the address contains a specific street address."})
              // }
          // } else {
          //   console.log(response.data);
          //   this.props.stopLoading()
          //   this.setState({error_address: "Make sure the address you type located in Israel."})
          // }
        })
      .catch( (error) => {
        //console.log(error);
      });
  }

  getCompanyType = (id) => {
    switch (id) {
      case "0":
        return "Ltd"
        break;
      case "1":
        return "SOHO"
        break;
      case "2":
        return "Society"
        break;
      default:
        return "Society"
    }
  }

  registerPhotographer = (country, city, street) => {
    this.props.startLoading()
    var companyType = ''
    if (this.state.isGroup == true) {
      companyType = this.getCompanyType(this.state.companyType)
    }
    var equipmentsToTransfer = this.state.cameras.concat(this.state.lenses).concat(this.state.drones).concat(this.state.trippleD)
    var equipments = []
    for (var i = 0; i < equipmentsToTransfer.length; i++) {
      equipments.push(equipmentsToTransfer[i].value)
    }
    var data = {
     salutation: this.state.salutation,
     firstname: this.state.firstName,
     lastname: this.state.lastName,
     name: this.state.firstName + " " + this.state.lastName,
     email: this.state.email,
     mobile: this.state.phone,
     country: country,
     city: city,
     street: street,
     another_equipment: this.state.comment,
     company_legal_name: this.state.companyName,
     company_registration_number: this.state.companyNumber,
     company_type: companyType,
     recruit_status: "Registered",
     equipments: equipments,
     portfolio_link: this.state.portfolio,
     isGroup: this.state.isGroup,
     acceptTerms: this.state.acceptTerms,
     ip: this.state.ip
    };
    console.log(data);
    ////console.log(
    axios.post('https://wesnapp.co.il/api/photographer/register',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log("result");
      console.log(response.data);

      this.props.stopLoading()
      if (response.data.constructor == Object) {
        // console.log("inside");
        if (response.data.success == "registeration completed.") {
          this.registerFinished(response.data.accountId, response.data.contactId)
        } else {
          this.registerFailed(response.data)
        }
      } else {
        this.registerFailed(response.data)
      }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000)
    return code
  }

  continueToVerification = () => {
    this.setState({smsDialog: true})
    this.setState({code: this.generateRandomCode()}, () => {
      this.sendVerificationCode()
    })
  }

  verifyCode = () => {
    this.setState({errorCode: false})
    if (this.state.code == this.state.inputCode) {
      this.setState({smsDialog: false}, () => {
        this.registerPhotographer(this.state.country, this.state.city, this.state.street)
      })
    } else {
      this.setState({errorCode: true})
    }
  }

  sendVerificationCode = () => {
    var data = {
      "phone": ("+" + this.state.countryCode + this.state.phone),
      "text": 'WeSnapp verification code: ' + this.state.code
    }
    axios.post('https://wesnapp.co.il' + '/api/sms/send-message',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  handleGroupOrNot = (e) => {
    this.setState({isGroup: e.target.checked})
  }

  handleChangeTypeOfCompany = (e) => {
    this.setState({companyType: e.target.value})
  }

  acceptTermsAndConditions = (e) => {
    this.setState({acceptTerms: e.target.checked})
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <SmsDialog main={this}/>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={6} sm={6} md={6}>
            <input type={"text"} className="CustomInput" onChange={this.handleChangeText("firstName")} placeholder="First name*" style={{float: 'right'}} />
            <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_firstName}</div>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <input type={"text"} className="CustomInput" onChange={this.handleChangeText("lastName")} placeholder="Last name*" style={{float: 'right'}} />
            <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_lastName}</div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <input type={"email"} className="CustomInput" onChange={this.handleChangeText("email")} placeholder="Email*" style={{float: 'right'}} />
            <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_email}</div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <input type={"phone"} className="CustomInput" onChange={this.handleChangeText("phone")} placeholder="Phone*" style={{float: 'right'}} />
            <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_phone}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              style={{width: '100%', textAlign: 'left', direction: 'ltr', marginLeft: -10}}
              onPlaceSelected={(place) => {
                console.log(place);
                if (place.hasOwnProperty('types')) {
                  if (place.types.includes('street_address') || place.types.includes('route')) {
                    // console.log(true);
                    this.setState({error_address: '', address: place.formatted_address})
                  } else {
                    // console.log(false);
                    this.setState({error_address: "Choose specific address (city, street and number)"})
                  }
                } else {
                  // console.log(false);
                  this.setState({error_address: "Choose specific address (city, street and number)"})
                }
              }}
              placeholder="Choose specific address (city, street and number)"
              className="CustomInput"
              types={['address']}
              componentRestrictions={{country: "il"}}
              />
              <div style={{float: 'left', color: 'red', marginLeft: 0,marginTop: 0}}>{this.state.error_address}</div>
          </Grid>
          {
            // <Grid item xs={12} sm={12} md={12}>
            //   <GooglePlacesAutocomplete inputClassName="CustomInput" onChange={this.handleChangeText("address")} placeholder="Address* (Select from options)" inputStyle={{marginLeft: -7.5}}
            //     onSelect={this.handleChangeLocation}
            //   />
            //   <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_address}</div>
            // </Grid>
          }
          <Grid item xs={12} sm={12} md={12}>
            <input type={"text"} className="CustomInput" onChange={this.handleChangeText("portfolio")} placeholder="Portfolio link" style={{float: 'right'}} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <EquipmentsCategory main={this}/>
          </Grid>
          { this.state.eqType == 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <EquipmentsCamerasAndLenses style={{marginTop: 0}} main={this}/>
            </Grid>
          )}
          { this.state.eqType == 1 && (
            <Grid item xs={12} sm={12} md={12}>
              <EquipmentsDrones style={{marginTop: 0}} main={this}/>
            </Grid>
          )}
          { this.state.eqType == 2 && (
            <Grid item xs={12} sm={12} md={12}>
              <Equipments3D style={{marginTop: 0}} main={this}/>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <div style={{float: 'left', marginLeft: -10}}>
              <Switch
                 checked={this.state.notInList}
                 onChange={this.handleChangeSwitch('notInList')}
                 value="checkedB"
                 style={{textAlign: 'left', float: 'left'}}
                 color="primary"
                 inputProps={{ 'aria-label': 'primary checkbox' }}
               /> Your equipment is not on the list?
            </div>
          </Grid>
          {this.state.notInList && (
            <Grid item xs={12} sm={12} md={12}>
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("comment")} placeholder="Add equipment which is not in the list" style={{marginLeft: 0}} />
              <div style={{float: 'left', textAlign: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_comment}</div>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <label class="container">Are you a company/business?
              <input type="checkbox" checked={this.state.isGroup} onChange={this.handleGroupOrNot} />
              <span class="checkmark"></span>
            </label>
          </Grid>
          { this.state.isGroup && (
            <Grid item xs={12} sm={4} md={4}>
              <select onChange={this.handleChangeTypeOfCompany} style={{borderRadius: 20}} className="CustomInput" style={{backgroundColor: 'white', borderRadius: 20, marginTop: 5, marginLeft: 0}}>
                <option value="0">Business type*</option>
                <option value="1">Company Limited</option>
                <option value="2">Authorized dealer</option>
                <option value="3">Exempt dealer</option>
              </select>
            </Grid>
          )}
          { this.state.isGroup && (
            <Grid item xs={6} sm={4} md={4}>
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("companyName")} placeholder="Business name*" style={{}} />
            </Grid>
          )}
          { this.state.isGroup && (
            <Grid item xs={6} sm={4} md={4}>
              <input type={"text"} className="CustomInput" onChange={this.handleChangeText("companyNumber")} placeholder="Business number*" style={{float: 'right'}} />
            </Grid>
          )}
          { this.state.isGroup && (
            <Grid item xs={12} sm={12} md={12}>
              <div style={{float: 'left', color: 'red', marginTop: 0}}>{this.state.error_group}</div>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <label class="container">I accept the <a target="_blank" href="https://wesnapp.io/terms-conditions"><b style={{color: 'rgb(135,142,225)'}}>Terms and Conditions</b></a>
              <input type="checkbox" checked={this.state.acceptTerms} onChange={this.acceptTermsAndConditions} />
              <span class="checkmark"></span>
            </label>
            <div style={{float: 'left', color: 'red', marginTop: 3, textAlign: 'left'}}>{this.state.error_acceptTerms}</div>
          </Grid>
        {
        }
          <Grid item xs={12} sm={12} md={12}>
            <Button onClick={() => this.registerClicked()} elevation={0} style={{ textTransform: 'none', marginTop: 10, marginBottom: 60, height: 40, width: 150, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>Register</b>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
