import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import '../../App.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class NewsPage extends React.Component {

  state = {
    code: ''
  };

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });
  };

  continueClicked = () => {
    // Task: Add random code number verification
    this.checkRandomCode()
  }

  checkRandomCode = () => {
    if (this.state.code == this.props.main.state.randomCode) {
      this.checkIfUserIsRegistered()
    } else {
      this.props.handleOpenAlert('Incorrect verification code')
    }
  }

  checkIfUserIsRegistered = () => {
    this.props.startLoading()
    var data = {
      "phone": this.props.main.state.phone
    }
    axios.post('https://wesnapp.co.il/api/login/check-if-phone-exists',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.props.stopLoading()
        //console.log(response.data);
        if(response.data.length > 0) {
          //console.log("NAVIGATE TO GET USER IN");
          // alert("You are already exists in our system")
          this.props.main.setState({step:5})
          // this.props.getUserIn(response.data)
        } else {
          this.props.main.setState({step:2})
        }
      })
    .catch( (error) => {
      this.props.stopLoading()
      //console.log(error);
    });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
            <input type={"number"} className="CustomInput" onChange={this.handleChangeText("code")} placeholder="Verification Code" style={{textAlign: 'center'}} />
            <div onClick={() => this.props.main.setState({step:0})} style={{textAlign: 'left', marginLeft: 10}}><u><b>Enter another phone number</b></u></div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button onClick={() => this.checkRandomCode()} elevation={0} style={{ textTransform: 'none', marginTop: 30, height: 40, width: 150, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>Continue</b>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
