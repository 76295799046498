import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import '../../App.css';

import ReactFlagsSelect from 'react-flags-select';

//import css module
import 'react-flags-select/css/react-flags-select.css';

//OR import sass module
import 'react-flags-select/scss/react-flags-select.scss';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class NewsPage extends React.Component {

  state = {
    code: '972',
    phone: ''
  };

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value });
  };

  continueClicked = () => {
    // Task: Add phone number verification
    this.sendVerificationCode()
  }

  generateRandomKey = () => {
    var randomKey = (Math.floor((Math.random() * 9999) + 1000)).toString()
    //console.log(randomKey);
    return randomKey
  }

  sendVerificationCode = () => {
    var randomCode = this.generateRandomKey()
    this.props.startLoading()
    // console.log(randomCode);
    var data = {
      "phone": ("+" + this.state.code + this.state.phone),
      "code": randomCode
    }
    axios.post('https://wesnapp.co.il/api/login/verification-code',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        //console.log(response);
        this.props.stopLoading()
        this.props.main.setState({step: 1, phone: this.state.phone, countryCode: this.state.code, randomCode: randomCode})
      })
    .catch( (error) => {
      //console.log(error);
    });
  }
  //            <input defaultValue={"972"} type={"phone"} className="CustomInput" onChange={this.handleChangeText("code")} placeholder="Code" style={{textAlign: 'center', float: 'left'}} />

  onSelectFlag = (flag) => {
    switch (flag) {
      case 'IL':
        this.setState({code: '972'})
        break;
      case 'US':
        this.setState({code: '1'})
        break;
      default:
        this.setState({code: '972'})
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={3} sm={2} md={2}>
            <div style={{height: 10}}></div>
            <ReactFlagsSelect onSelect={this.onSelectFlag} showSelectedLabel={false} searchable={false} style={{paddingTop:30}} searchPlaceholder="Country" countries={["US", "IL"]} defaultCountry="IL"/>
          </Grid>
          <Grid item xs={9} sm={10} md={10}>
            <input type={"phone"} className="CustomInput" onChange={this.handleChangeText("phone")} placeholder="Phone" style={{float: 'right'}} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button onClick={() => this.continueClicked()} elevation={0} style={{ textTransform: 'none', marginTop: 30, height: 40, width: 150, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>Continue</b>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
