import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import '../../App.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class NewsPage extends React.Component {

  state = {
  }

  anotherPhoneNumber = () => {
    this.props.main.setState({step: 0})
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
            <div style={{marginTop: '1%', textAlign: 'center', color: 'rgb(90,88,110)', fontSize: 25}}><b>The phone number that you entered is already exists in our system.</b></div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button onClick={() => this.anotherPhoneNumber()} elevation={0} style={{ textTransform: 'none', paddingRight: 10, paddingLeft: 10, marginTop: 30, height: 40, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
              <b>Enter another phone number</b>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
