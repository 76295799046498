import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PhoneInput from './PhoneInput';
import CodeInput from './CodeInput';
import RegisterPhotographer from './RegisterPhotographer';
import PhoneExist from './PhoneExist'
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import LoadingScreen from 'react-loading-screen';
import DescriptionDialog from '../Dialog/DescriptionDialog.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    fontSize: '20px',
    textAlign: 'left'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
});


class Login extends React.Component {

  state = {
    titles: ['Enter your phone number', 'Enter verification code', '','',''],
    step: 2,
    openAlert: false,
    isLoading: false,
    phone: '',
    completed: false,
    alertText: ''
  };

  componentDidMount() {
    this.updateRouteToDashboard()
  }

  updateRouteToDashboard = () => {
     if (window.location.pathname == "/") {
       window.location.pathname = "/Photographer"
     }
   }

  // Loading functions
  startLoading = () => {
    this.setState({isLoading: true})
  }

  stopLoading = () => {
    this.setState({isLoading: false})
  }

  // Alert functions
  handleCloseAlert = () => {
    this.setState({openAlert: false})
  }

  handleOpenAlert = (text) => {
    this.setState({alertText: text, openAlert: true})
    this.stopLoading()
  }

  changeStepTo = (number) => {
    this.setState({step: number})
  }

  backToWesnappIO = () => {
    localStorage.removeItem('wesnapp-registered');
    window.location.href = "https://www.wesnapp.io"
  }

  getUserIn = (userData) => {
    localStorage.setItem("wesnapp-mobile", userData[0].MobilePhone);
    localStorage.setItem("wesnapp-contactId", userData[0].Id);
    localStorage.setItem("wesnapp-accountId", userData[0].AccountId);
    var token = uuid.v1()
    this.saveUUID(userData[0].AccountId)
  }

  saveUUID = (accountId) => {
    axios.post('https://wesnapp.co.il/api/login/save-token',{id: accountId}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      const cookies = new Cookies();
      cookies.set('wesnapp-token', response.data.token, { path: '/' });
      window.location.reload();
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  render() {
    const { classes, theme } = this.props;
      return (
      <div className={classes.root}>
        <DescriptionDialog open={this.state.openAlert}  closeAlert={this.handleCloseAlert} text={this.state.alertText}/>
        {this.state.isLoading == true && (
          <div style={{color: 'black', fontSize: 16}}>
            <div className="loading">
            {
              // <img src={require('../../Assets/Images/wesnappLogoBlack.png')} style={{height: 30, marginTop: 'calc(40vh - 15px)'}}/>
            }
            <CircularProgress style={{marginTop: 'calc(40vh - 15px)', marginBottom: 25}}/>
            <div><b>Process can take few seconds</b></div>
            </div>
          </div>
        )}
        {
        // <AppBar elevation={0} position="static" style={{textAlign: 'center', backgroundColor: 'white', border: '1px solid rgba(244, 244, 244, 1.0)'}}>
        //   <Toolbar>
        //     <Typography variant="h6" color="inherit" className={classes.grow}>
        //       <img src={require('../../Assets/Images/wesnappLogoBlack.png')} style={{height: 40, marginTop: 16, marginLeft: 12, marginBottom: 10}}/>
        //     </Typography>
        //   </Toolbar>
        // </AppBar>
        }
        <main className={classes.content}>
          <Grid container spacing={24}>
            <Grid item xs={0} sm={2} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={24}>
                {
                  this.state.completed == true ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{marginTop: '1%', textAlign: 'center', color: 'rgb(90,88,110)', fontSize: 32}}><b>Thank you for registering.</b></div>
                      <div style={{textAlign: 'center', marginTop: 25, color: 'rgb(90,88,110)', marginBottom: '1%', fontSize: 20}}>We will be in touch with you shortly.</div>
                      {
                      // <div style={{textAlign: 'center', marginTop: 25, color: 'rgb(90,88,110)', marginBottom: '1.5%', fontSize: 20}}>
                      //   <Button onClick={() => this.backToWesnappIO()} elevation={0} style={{ textTransform: 'none', marginTop: 10, marginBottom: 60, height: 40, width: 180, borderRadius: 20, textColor: 'white', color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)"}} className={classes.button}>
                      //     <b>Back to our website</b>
                      //   </Button>
                      // </div>
                    }
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{marginTop: '1%', textAlign: 'center', color: 'rgb(90,88,110)', fontSize: 32}}><b>Join us on WeSnapp</b></div>
                      <div style={{textAlign: 'center', marginTop: 25, color: 'rgb(90,88,110)', marginBottom: '1.5', fontSize: 20}}>And Start making Money</div>
                    </Grid>
                  )
                }
                <Grid item xs={0} sm={2} md={2}>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  {
                    this.state.completed == false && (
                      <div style={{marginTop: 20}}>
                        {this.state.step == 0 && (<PhoneInput main={this} changeStepTo={this.changeStepTo} handleOpenAlert={this.handleOpenAlert} handleCloseAlert={this.handleCloseAlert} startLoading={this.startLoading} stopLoading={this.stopLoading} />)}
                        {this.state.step == 1 && (<CodeInput getUserIn={this.getUserIn} main={this} changeStepTo={this.changeStepTo} handleOpenAlert={this.handleOpenAlert} handleCloseAlert={this.handleCloseAlert} startLoading={this.startLoading} stopLoading={this.stopLoading} />)}
                        {this.state.step == 2 && (<RegisterPhotographer main={this} changeStepTo={this.changeStepTo} handleOpenAlert={this.handleOpenAlert} handleCloseAlert={this.handleCloseAlert} startLoading={this.startLoading} stopLoading={this.stopLoading} />)}
                        {this.state.step == 4 && (<RegisterPhotographer main={this} changeStepTo={this.changeStepTo} handleOpenAlert={this.handleOpenAlert} handleCloseAlert={this.handleCloseAlert} startLoading={this.startLoading} stopLoading={this.stopLoading} />)}
                        {this.state.step == 5 && (<PhoneExist main={this} changeStepTo={this.changeStepTo} handleOpenAlert={this.handleOpenAlert} handleCloseAlert={this.handleCloseAlert} startLoading={this.startLoading} stopLoading={this.stopLoading} />)}
                      </div>
                    )
                  }
                </Grid>
                <Grid item xs={0} sm={2} md={2}>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0} sm={2} md={3}>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
